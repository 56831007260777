//** Temp Assets */
import Advertisements from "../../components/ads/Advertisements";
import { Divider } from "antd";

export default function CitySidebar(props) {
  return (
    <>
      <div className="ad-space">
        <Advertisements addLocation="citySidebar" type="square" />
        <Divider />
        <Advertisements addLocation="citySidebar" type="square" />
        <Divider />
        <Advertisements addLocation="citySidebar" type="square" />
      </div>
    </>
  );
}
