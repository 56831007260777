import React, { useEffect, useState } from "react";

//* Placeholder Assets
import square from "../../assets/images/ad-space-square.jpg";
import skyscraper from "../../assets/images/ad-space-skyscraper.jpg";
import axiosGeneral from "../../utils/axiosGeneral";
import { Carousel } from "antd";

export default function Advertisements(props) {
  const { type } = props;

  const [ad, setAd] = useState({});

  useEffect(() => {
    // const APIURL = `/api/advertisement/random/${type}`;
    // axiosGeneral()
    //   .get(APIURL)
    //   .then((res) => {
    //     setAd(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //   });
    getAds();
  }, []);

  const renderAd = (ad) => (
    <a href={`/business/${ad.businessHandle}`} key={ad.id}>
      <img
        src={
          ad.meta_data.find((meta) => meta.meta_key === "ad_image").meta_value
        }
        alt={`${type} Ad`}
        style={{ width: "100%" }}
      />
    </a>
  );

  const getAds = async () => {
    try {
      const response = await axiosGeneral().get(
        `/api/advertisement/getAddsForPublic/${
          type === "square" ? "pay" : "free"
        }`
      );
      console.log("response", response.data[0]);
      setAd(response.data[0]);
      // return response.data;
    } catch (error) {
      console.error("Error getting user ads:", error);
    }
  };

  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  return (
    <div className="advertisement">
      {type === "square" &&
        (ad.id ? (
          <a href={`/business/${ad.businessHandle}`}>
            <img
              src={
                ad.meta_data.find((meta) => meta.meta_key === "ad_image")
                  .meta_value
              }
              alt="Square Ad"
            />
          </a>
        ) : (
          <img src={square} alt="Square Ad Placeholder" />
        ))}

      {type === "skyscraper" &&
        (ad.id ? (
          <a href={`/business/${ad.businessHandle}`}>
            <img src={ad.meta_data.ad_image} alt="Skyscraper Ad" />
          </a>
        ) : (
          <img src={skyscraper} alt="Skyscraper Ad Placeholder" />
        ))}

      {type === "carousel" && (
        <Carousel autoplay>
          {/* {ad.length > 0 ? (
            ad.map(renderAd)
          ) : (
            <img src={square} alt="Ad Placeholder" />
          )} */}
          <div>
            <h3 style={contentStyle}>1</h3>
          </div>
          <div>
            <h3 style={contentStyle}>2</h3>
          </div>
          <div>
            <h3 style={contentStyle}>3</h3>
          </div>
          <div>
            <h3 style={contentStyle}>4</h3>
          </div>
        </Carousel>
      )}
    </div>
  );
}
