import React, { useEffect, useState } from "react";
import axiosWithAuth from "../../../src/utils/axiosWithAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Form,
  Input,
  Divider,
  Checkbox,
  Modal,
  Button,
  message,
  Steps,
  Drawer,
  Calendar,
  Alert,
} from "antd";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { FE_URL } from "../../common/constants";

export default function ProfileServicesPage(props) {
  const { business } = props;
  const [services, setServices] = useState([]);
  const [turns, setTurns] = useState([]);
  const [EnabledDaysCalendar, setEnabledDaysCalendar] = useState([]);
  const [dailyAppointments, setDailyAppointments] = useState([]);
  const [open, setOpen] = useState(false);

  const [SelectedTurnDay, setSelectedTurnDay] = useState(() =>
    (moment()._d + "").substring(4, 15)
  );
  const [SelectedService, setSelectedService] = useState(null);
  const [SelectedTurn, setSelectedTurn] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [cart, setCart] = useState([]);
  const [totalPriceCart, setTotalPriceCart] = useState(0);

  const { Step } = Steps;

  useEffect(() => {
    const APIEndPoint = `/api/products/fe/business/${business.id}/service`;
    axiosWithAuth()
      .get(APIEndPoint)
      .then((res) => {
        setServices(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [business.id]);

  const getDailyAppointments = async (newValue) => {
    const day = newValue.format("DD");
    const month = newValue.format("MMM");
    const year = newValue.format("YYYY");
    const business_id = business.id;

    const APIURL = `/api/appointments/list/daily`;

    const data = {
      day,
      month,
      year,
      business_id,
    };

    await axiosWithAuth()
      .post(APIURL, data)
      .then((res) => {
        let temp = res.data.map((element) => {
          return moment.utc(element.start_time).format("HH:mm");
        });
        setDailyAppointments(temp);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  /* Calendar */
  const [value, setValue] = useState(moment());
  const [selectedValue, setSelectedValue] = useState(moment());

  const onSelect = (newValue) => {
    getDailyAppointments(newValue);
    setValue(newValue);
    setSelectedTurnDay(newValue.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (SelectedService != null) {
      createTurnList(SelectedService, value);
    }
  }, [dailyAppointments, SelectedService]);

  const selectService = (id) => {
    document
      .querySelectorAll(".single-service-container_select")
      .forEach((element) => element.classList.remove("selected_service"));
    document.getElementById(id).classList.add("selected_service");
    services.map((service) => {
      if (service.id == id) {
        setSelectedService(service);
      }
    });
  };

  const selectTurn = (id) => {
    document
      .querySelectorAll(".single-turn-container_select")
      .forEach((element) => element.classList.remove("selected_turn"));
    document.getElementById(id).classList.add("selected_turn");
    turns.map((turn) => {
      if (turn.TurnId == id) {
        setSelectedTurn(turn);
      }
    });
  };

  const createTurnList = (service, newValue, intervalBetweenTurns = 10) => {
    if (newValue !== undefined) {
      setSelectedTurnDay((newValue._d + "").substring(4, 15));
      setValue(newValue);
      setSelectedValue(newValue);
    }

    var appointmentDuration = service.meta_data.service_duration_time;
    var appointmentDurationHours = Number(appointmentDuration.substr(0, 2));
    var appointmentDurationMinutes = Number(appointmentDuration.substr(3, 2));

    var currentAvailabilityRulesHours = [];
    var currentTurnList = [];

    service.availabilityRules.forEach((availabilityRule) => {
      if (availabilityRule.rule_type === "Range of hours") {
        if (
          newValue !== undefined &&
          availabilityRule.rule_val_1 === newValue.format("dddd").toLowerCase()
        ) {
          currentAvailabilityRulesHours.push(availabilityRule);
        }
      }
    });

    var id = 1;

    const now = moment(); // Momento actual
    const minToMakeAppointment =
      service.meta_data.service_min_to_make_appointment;
    const minToMakeAppointmentPeriod =
      service.meta_data.service_min_to_make_appointment_period;

    currentAvailabilityRulesHours.forEach((currentAvailabilityRulesHour) => {
      var startTime = moment(currentAvailabilityRulesHour.rule_val_2, "HH:mm");
      var temporalTime = moment(
        currentAvailabilityRulesHour.rule_val_2,
        "HH:mm"
      );
      temporalTime = temporalTime.add(appointmentDurationMinutes, "minutes");
      temporalTime = temporalTime.add(appointmentDurationHours, "hours");
      var endTime = temporalTime;

      while (
        endTime.isSameOrBefore(
          moment(currentAvailabilityRulesHour.rule_val_3, "HH:mm")
        )
      ) {
        var insert = true;

        // Verificar la restricción de horas si aplica
        if (minToMakeAppointmentPeriod === "hours") {
          const minAppointmentTime = now
            .clone()
            .add(minToMakeAppointment, "hours");
          if (
            newValue.isSame(now, "day") &&
            startTime.isBefore(minAppointmentTime)
          ) {
            insert = false; // No crear turnos antes del tiempo mínimo permitido
          }
        }

        var turn = {
          TurnId: id,
          TurnDay: newValue.format("MMM DD YYYY"),
          availableTurns: service.meta_data.service_max_appointments,
          TurnStartTime:
            startTime._d.getHours() +
            ":" +
            ((startTime._d.getMinutes() + "").length > 1
              ? startTime._d.getMinutes()
              : "0" + startTime._d.getMinutes()),
          TurnEndTime:
            endTime._d.getHours() +
            ":" +
            ((endTime._d.getMinutes() + "").length > 1
              ? endTime._d.getMinutes()
              : "0" + endTime._d.getMinutes()),
        };

        if (
          SelectedService.meta_data.service_min_to_make_appointment_period ===
          "hours"
        ) {
          if (
            moment(
              SelectedTurnDay +
                " " +
                startTime._d.getHours() +
                ":" +
                ((startTime._d.getMinutes() + "").length > 1
                  ? startTime._d.getMinutes()
                  : "0" + startTime._d.getMinutes())
            ).format("MM DD YYYY HH:mm") <
            moment()
              .add(
                SelectedService.meta_data.service_min_to_make_appointment,
                "hours"
              )
              .format("MM DD YYYY HH:mm")
          ) {
            insert = false;
          }
        }

        if (insert) {
          currentTurnList.push(turn);
        }

        // Agregar la duración de la cita y el intervalo entre turnos
        startTime.add(appointmentDurationMinutes, "minutes");
        startTime.add(appointmentDurationHours, "hours");
        startTime.add(intervalBetweenTurns, "minutes"); // Agregar el intervalo entre turnos
        endTime.add(appointmentDurationMinutes, "minutes");
        endTime.add(appointmentDurationHours, "hours");
        endTime.add(intervalBetweenTurns, "minutes"); // Agregar el intervalo entre turnos

        id += 1;
      }

      for (let i = currentTurnList.length - 1; i >= 0; i--) {
        const turn = currentTurnList[i];

        // Convertir el tiempo de inicio y finalización del turno a momentos
        const turnStartTime = moment(
          turn.TurnDay + " " + turn.TurnStartTime,
          "MMM DD YYYY HH:mm"
        );
        const turnEndTime = moment(
          turn.TurnDay + " " + turn.TurnEndTime,
          "MMM DD YYYY HH:mm"
        );

        let overlap = false;

        // Verificar solapamiento con turnos reservados
        dailyAppointments.forEach((appointment) => {
          const appointmentStartTime = moment(
            appointment.startTime,
            "MMM DD YYYY HH:mm"
          );
          const appointmentEndTime = moment(
            appointment.endTime,
            "MMM DD YYYY HH:mm"
          );

          // Comprobar si hay solapamiento
          if (
            (turnStartTime.isSameOrAfter(appointmentStartTime) &&
              turnStartTime.isBefore(appointmentEndTime)) ||
            (turnEndTime.isAfter(appointmentStartTime) &&
              turnEndTime.isSameOrBefore(appointmentEndTime)) ||
            (appointmentStartTime.isSameOrAfter(turnStartTime) &&
              appointmentStartTime.isBefore(turnEndTime))
          ) {
            overlap = true;
          }
        });

        // Eliminar el turno si hay solapamiento o es una fecha pasada
        if (
          overlap ||
          moment(turn.TurnDay + " " + turn.TurnStartTime).isBefore(moment())
        ) {
          currentTurnList.splice(i, 1);
        }
      }

      setTurns(currentTurnList);
    });
  };

  const disableDays = () => {
    var currentAvailabilityRulesDays = [];
    SelectedService.availabilityRules.forEach((availabilityRule) => {
      if (!currentAvailabilityRulesDays.includes(availabilityRule.rule_val_1)) {
        currentAvailabilityRulesDays.push(availabilityRule.rule_val_1);
      }
    });
    setEnabledDaysCalendar(currentAvailabilityRulesDays);
  };

  const onFinish = async (values) => {
    if (values.agreement === true) {
      setUserInfo(values);
      next();
    } else {
      const modal = Modal.error({
        title: "Error",
        content: `You must accept the terms and conditions of Bizzll!`,
      });

      setTimeout(() => {
        modal.destroy();
      }, 5000);
    }
  };

  const bookAppointment = async () => {
    var cartItem = {
      itemID: SelectedService.id,
      itemName: SelectedService.name,
      itemPrice: SelectedService.meta_data.service_price,
      itemQuantity: 1,
      businessID: SelectedService.business_id,
      itemTotal: SelectedService.meta_data.service_price * 1,
      businessStripeID: business.meta_data.stripe_account_id,
      itemType: "service_product",
      itemDescription: `Reserved a block time of ${SelectedService.meta_data.service_duration_time}`,
      startTime: moment(
        `${SelectedTurnDay} ${SelectedTurn.TurnStartTime}`
      ).format("YYYY-MM-DD HH:mm:ss"),
    };

    setTotalPriceCart(
      (parseFloat(totalPriceCart) || 0) +
        (parseFloat(SelectedService.meta_data.service_price) || 0)
    );

    const newCart = [...cart, cartItem];

    setCart(newCart);
    setCurrent(0);
    showDrawer();

    message.success("Item Added to Cart!");
  };

  const handleCheckout = async () => {
    // Check to ensure that there are legitimate tickets being called
    if (cart && cart.length > 0) {
      // First, remove any item from localstorage
      // localStorage.removeItem("cart");

      console.log("JSON.stringify(cart)", JSON.stringify(cart));
      console.log("cart", cart);

      // Then, add the new item in the local storage
      window.localStorage.setItem("cart", JSON.stringify(cart));

      // redirect to checkout
      window.location.replace(`${FE_URL}/checkout`);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const steps = [
    {
      content: (
        <div>
          <div className="service_container-services">
            {services.map((service, index) => {
              return (
                <div
                  key={service + "_" + index}
                  className="single-service-container"
                >
                  <div
                    className="single-service-container_select"
                    id={service.id}
                    onClick={(element) => {
                      selectService(element.target.id);
                    }}
                  ></div>
                  <div className="single-service-container_image">
                    <img
                      src={service.meta_data.service_logo}
                      alt="Service Logo"
                    ></img>
                  </div>
                  <div className="single-service-container_data">
                    <div className="single-service-container_data-row-1">
                      <label>{service.name}</label>
                    </div>
                    <div className="single-service-container_data-row-2">
                      <div className="single-service-container_data-row-2-duration">
                        <label>Duration: </label>{" "}
                        <label className="single-service-container_data-row-2-duration-label">
                          {service.meta_data.service_duration_time}
                        </label>
                      </div>
                      <div className="single-service-container_data-row-2-price">
                        <label>Price: </label>
                        <label className="single-service-container_data-row-2-price-label">
                          $ {service.meta_data.service_price}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
              float: "right",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                if (SelectedService != null) {
                  disableDays();
                  createTurnList(SelectedService);
                  next();
                } else {
                  const modal = Modal.error({
                    title: "Error",
                    content: `You must select a service!`,
                  });

                  setTimeout(() => {
                    modal.destroy();
                  }, 5000);
                }
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div>
          <div className="service_container-calendar">
            <div className="service_container-calendar_calendar">
              <Alert
                message={`You selected date: ${selectedValue?.format(
                  "YYYY-MM-DD"
                )}`}
              />
              <Calendar
                value={value}
                onSelect={onSelect}
                picker="month"
                disabledDate={(date) => {
                  const currentWeekday = date.format("dddd").toLowerCase();
                  const now = moment();

                  // Deshabilitar si el día de la semana no está habilitado
                  if (!EnabledDaysCalendar.includes(currentWeekday)) {
                    return true;
                  }

                  // Obtener valores de restricciones
                  const minToMakeAppointment =
                    SelectedService.meta_data.service_min_to_make_appointment;
                  const minToMakeAppointmentPeriod =
                    SelectedService.meta_data
                      .service_min_to_make_appointment_period;

                  // Deshabilitar fechas anteriores a la fecha mínima permitida
                  if (minToMakeAppointmentPeriod === "days") {
                    if (
                      date <
                      now.add(minToMakeAppointment, "days").startOf("day")
                    ) {
                      return true;
                    }
                  } else if (minToMakeAppointmentPeriod === "hours") {
                    if (date.isSame(now, "day")) {
                      const minAppointmentTime = now
                        .clone()
                        .add(minToMakeAppointment, "hours");
                      if (date.isBefore(minAppointmentTime)) {
                        return true;
                      }
                    }
                  }

                  // Deshabilitar fechas pasadas
                  if (date && date < now.startOf("day")) {
                    return true;
                  }

                  // Deshabilitar días de baja (day_off)
                  const dayOffs = SelectedService.availabilityRules
                    .filter((rule) => rule.rule_type === "day_off")
                    .map((rule) => moment(rule.rule_val_1, "YYYY-MM-DD"));

                  if (dayOffs.some((dayOff) => dayOff.isSame(date, "day"))) {
                    return true;
                  }

                  return false;
                }}
              />
            </div>
            <div className="service_container-calendar_turn">
              {turns.map((turn, index) => {
                return (
                  <div
                    key={turn + "_" + index}
                    className="single-turn-container"
                  >
                    <div
                      className="single-turn-container_select"
                      id={turn.TurnId}
                      onClick={(element) => {
                        selectTurn(element.target.id);
                      }}
                    ></div>
                    <div className="single-turn-container_data">
                      <div className="">
                        <label>
                          {moment(turn.TurnStartTime, "HH:mm").format(
                            "HH:mm A"
                          )}
                        </label>
                      </div>
                      <div className="">
                        <label> {turn.availableTurns} Slot(s) left </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
              float: "right",
            }}
          >
            <Button
              type="primary"
              style={{
                margin: "0 8px",
              }}
              onClick={() => {
                setSelectedService(null);
                prev();
              }}
            >
              Previous
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (SelectedTurn != null) {
                  disableDays();
                  next();
                } else {
                  const modal = Modal.error({
                    title: "Error",
                    content: `You must select a turn!`,
                  });

                  setTimeout(() => {
                    modal.destroy();
                  }, 5000);
                }
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div>
          <Form layout="vertical" onFinish={onFinish}>
            <div className="service_container-info">
              <h3>Your Info</h3>
              <Form.Item
                name="firstname"
                label="Firstname"
                rules={[
                  {
                    required: true,
                    message: "Please enter your firstname",
                  },
                ]}
              >
                <Input placeholder="Enter your firstname" />
              </Form.Item>
              <Form.Item
                name="lastname"
                label="Lastname"
                rules={[
                  {
                    required: true,
                    message: "Please enter your lastname",
                  },
                ]}
              >
                <Input placeholder="Enter your email address" />
              </Form.Item>
              <Form.Item
                name="email_address"
                label="Email Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                ]}
              >
                <Input type="email" placeholder="Enter your email address" />
              </Form.Item>
              <Form.Item name="note" label="Note">
                <Input.TextArea placeholder="Enter note details" />
              </Form.Item>
              <Form.Item name="agreement" valuePropName="checked">
                <Checkbox>
                  By making an order in BIZZLL, you are agreeing to our{" "}
                  <a href="/terms-conditions" target="_blank">
                    Terms & Conditions
                  </a>
                </Checkbox>
              </Form.Item>
            </div>
            <div
              style={{
                marginTop: 15,
                marginBottom: 15,
                float: "right",
                display: "flex",
              }}
            >
              <Button
                type="primary"
                style={{
                  margin: "0 8px",
                }}
                onClick={() => prev()}
              >
                Previous
              </Button>
              <Form.Item
                style={{
                  marginBottom: 0,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      ),
    },
    {
      content: (
        <div>
          {SelectedService != null &&
            SelectedTurn != null &&
            userInfo != null && (
              <div className="service_container-confirmation">
                <h3>Confirmation</h3>
                <div
                  className="service_container-confirmation_container"
                  style={{
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    Customer
                  </label>
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {userInfo.firstname}
                  </label>
                </div>

                <div
                  className="service_container-confirmation_service_and_date"
                  style={{
                    margin: "20px auto",
                  }}
                >
                  <div
                    className="service_container-confirmation_container"
                    style={{
                      padding: "15px",
                      borderRight: "1px solid #CFD6E5",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Service
                    </label>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {SelectedService.name}
                    </label>
                  </div>
                  <div
                    className="service_container-confirmation_container"
                    style={{
                      padding: "15px",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Date & Time
                    </label>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {SelectedTurnDay}, {SelectedTurn.TurnStartTime} -{" "}
                      {SelectedTurn.TurnEndTime}
                    </label>
                  </div>
                </div>

                <Divider></Divider>

                <div className="service_container-confirmation_payable">
                  <label
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    Total Amount Payable
                  </label>
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      float: "right",
                    }}
                  >
                    $ {SelectedService.meta_data.service_price}
                  </label>
                </div>
              </div>
            )}
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
              float: "right",
            }}
          >
            <Button
              type="primary"
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
            <Button
              type="primary"
              onClick={() => {
                bookAppointment();
              }}
            >
              Book Appointment
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = async () => {
    setCurrent(current - 1);
  };

  //Delete from cart
  const removeHandler = (index) => {
    const currentCart = [...cart];
    setTotalPriceCart(totalPriceCart - cart[index].itemPrice);
    currentCart.splice(index, 1);
    setCart(currentCart);
  };

  return (
    <>
      <FontAwesomeIcon icon={faCartShopping} onClick={showDrawer} />
      <Drawer
        title={
          <div style={{ width: "100%", display: "flex" }}>
            <p style={{ fontWeight: "bold" }}>CART</p>{" "}
            <p style={{ margin: "0px 10px", fontWeight: "bold" }}>-</p>{" "}
            <p style={{ color: "gray", fontWeight: "bold" }}>
              {cart.length} Item
            </p>
          </div>
        }
        placement="right"
        onClose={onClose}
        visible={open}
      >
        {cart.length > 0 && (
          <>
            {cart.map((product, index) => {
              return (
                <>
                  <div style={{ marginBottom: "20px" }}>
                    <div
                      key={product + "_" + index}
                      className="single-service-container-cart"
                    >
                      <div className="single-service-container-cart_image">
                        <img
                          src={SelectedService.meta_data.service_logo}
                          alt="Logo"
                        />
                      </div>
                      <div className="single-service-container-cart_data">
                        <div className="single-service-container_data-row-1">
                          <label>{product.itemName}</label>
                        </div>
                        <div className="single-service-container_data-row-2-duration">
                          <label>Quantity: </label>{" "}
                          <label className="single-service-container_data-row-2-duration-label">
                            {product.itemQuantity}
                          </label>
                        </div>
                        <div className="single-service-container_data-row-2-price">
                          <label>Price: </label>
                          <label className="single-service-container_data-row-2-price-label">
                            $ {product.itemPrice}
                          </label>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this item from your cart?"
                            )
                          ) {
                            removeHandler(index);
                          }
                        }}
                        className="single-service-container-cart_delete"
                      >
                        <DeleteOutlined />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <Divider />
            <div style={{ width: "100%", height: "10%", display: "flex" }}>
              <div style={{ width: "50%", alignSelf: "center" }}>
                <p style={{ float: "left", margin: "0" }}>Total:</p>
              </div>
              <div style={{ width: "50%", alignSelf: "center" }}>
                <p
                  style={{
                    float: "right",
                    fontWeight: "bold",
                    margin: "0",
                  }}
                >
                  $ {totalPriceCart}
                </p>
              </div>
            </div>
            <Divider />
            <div style={{ display: "flex", margin: "auto" }}>
              <Button
                onClick={() => {
                  handleCheckout();
                }}
                type="primary"
              >
                Checkout
              </Button>
            </div>
          </>
        )}
        {cart.length === 0 && (
          <p
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            You have no items in your cart
          </p>
        )}
        <Button
          onClick={() => {
            window.localStorage.setItem("cart", JSON.stringify(cart));
          }}
        ></Button>
      </Drawer>
      <div className="service_container">
        <div className="steps-menu">
          <Steps current={current}>
            <Step
              title={
                <span>
                  <FontAwesomeIcon icon="fa-solid fa-list" />
                  <label style={{ marginLeft: "5px", cursor: "pointer" }}>
                    Services
                  </label>
                </span>
              }
            />
            <Step
              title={
                <span>
                  <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                  <label style={{ marginLeft: "5px" }}>Date & Time</label>
                </span>
              }
            />
            <Step
              title={
                <span>
                  <FontAwesomeIcon icon="fa-solid fa-id-card" />
                  <label style={{ marginLeft: "5px" }}>Your Info</label>
                </span>
              }
            />
            <Step
              title={
                <span>
                  <FontAwesomeIcon icon="fa-solid fa-calendar-check" />
                  <label style={{ marginLeft: "5px" }}>Confirmation</label>
                </span>
              }
            />
          </Steps>
        </div>

        <div>{steps[current].content}</div>
      </div>
    </>
  );
}
