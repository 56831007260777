//** Import Assets */
import noImage from "../../assets/images/Bizzll-DirectorySquare.png";

//** Import Components */
import moment from "moment";
import React from "react";

export default function ListingItem(props) {
  const { event } = props;

  // Acceder directamente al objeto meta_data
  const metaDataObj = event.meta_data || {};

  const listingData = {
    coverImg: metaDataObj.event_image || noImage,
    name: event.name || "No Title",
    event_mode: metaDataObj.event_mode || "N/A",
    userName: metaDataObj.event_title || "Unknown",
    eventDate: metaDataObj.event_date || "Date not available",
    eventStartTime: metaDataObj.event_starttime || "00:00",
    eventEndTime: metaDataObj.event_endtime || "00:00",
    businessLink: event.businessMetadata
      ? event.businessMetadata.business_meta_data.business_handle
      : "",
    eventDescription:
      metaDataObj.event_description || "No description available",
    eventVenue: metaDataObj.event_venue || "Venue not specified",
    eventVenueAddress:
      metaDataObj.event_venue_address || "Address not specified",
    tickets: event.tickets || [],
  };

  // Build URL
  let eventUrl = `/events/${event.id}`;

  return (
    <div className="listing-item">
      <div className="cover-img">
        <a href={eventUrl}>
          <div
            className="image"
            style={{
              backgroundSize: "cover",
              backgroundImage: `url(${listingData.coverImg})`,
            }}
          ></div>
        </a>
      </div>

      <div className="event-category"></div>

      <div className="event-info">
        <div className="event-name">
          <h3>{listingData.name}</h3>
        </div>

        <div className="event-description">
          {listingData.eventDescription &&
            listingData.eventDescription.substring(0, 90) + "..."}
        </div>

        <div className="event-meta">
          <div className="meta-row">
            <div className="label">Event Type</div>
            <div className="value">
              {listingData.event_mode === "online" ? "Online" : "On Location"}
            </div>
          </div>

          <div className="meta-row">
            <div className="label">Date</div>
            <div className="value">
              {moment(new Date(listingData.eventDate)).format("DD MMM. YYYY")}
            </div>
          </div>

          <div className="meta-row">
            <div className="label">Time</div>
            <div className="value">
              {moment(listingData.eventStartTime, "HH:mm").format("hh:mm A")} -{" "}
              {moment(listingData.eventEndTime, "HH:mm").format("hh:mm A")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
