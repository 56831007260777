//** Import Modules */
import { useEffect, useState } from "react";
import ChatBoxContainer from "../../components/chatbox/ChatBoxContainer";
import PageTitle from "../../components/header/PageTitle";

export default function AboutUs() {
  const [chatbox, setChatbox] = useState(false);

  useEffect(() => {
    const chatbox = JSON.parse(window.localStorage.getItem("chatbox"));
    if (chatbox) {
      setChatbox(chatbox);
    }
  }, []);

  const closeChatbox = () => {
    window.localStorage.removeItem("chatbox");
    setChatbox();
  };
  return (
    <div>
      <PageTitle pageTitle="About BIZZLL" />

      <div className="BIZZLL-video-player">
        <div className="inner-container about-container">
          <iframe
            width="100%"
            height="650.25px"
            style={{
              border: "3px solid #0099D9",
              borderRadius: "15px 15px 0 15px",
              boxShadow: "1px 1px 1px rgba(1, 1, 1, 0.3)",
            }}
            src="https://www.youtube.com/embed/gcO2ANwioC8?rel=0"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="About BIZZLL"
          ></iframe>

          <div
            className="content"
            style={{ marginTop: 60, textAlign: "justify" }}
          >
            <p>
              Welcome to Bizzll! The platform created to assist local businesses
              with innovative marketing and e-commerce solutions to drive sales.
            </p>

            <p>
              Created by Carmen Lopez, Manny Sarmiento and a group of
              Co-Founders, Bizzll is the post pandemic virtual solution where
              businesses and consumers, together, can rebuild and grow their
              communities through commerce. It is a marketplace designed to
              assist local businesses to create a digital presence to compete in
              the “new Normal” reality. Bizzll promotes business activity, to
              grow and reactivate local economies, boost the local supply chain,
              create jobs and grow the tax base to maintain and create services,
              such as roads, healthcare, education and more.
            </p>

            <p>
              In 2008 Carmen Lopez and Manny Sarmiento founded the Doral Chamber
              of Commerce in Doral, Florida. Their goal was to assist business
              owners with education that would enable them to establish a
              digital presence and to market their business using social media
              as it was required to survive and thrive.
            </p>

            <p>
              For more than 15 years Carmen and Manny have been helping local
              business communities navigate the ever-changing landscape of the
              modern digital business world. Manny Sarmiento author of Marketing
              Magic, is a passionate experienced business and marketing
              strategist, with a special focus on leveraging digital marketing
              to help businesses reach their goals, he has a comprehensive
              understanding of the online landscape, including SEO, content
              marketing, email campaigns, and social media. He is passionate
              about developing creative strategies to boost online presence,
              increase website visits, and drive sales. His knowledge and
              expertise have helped numerous businesses grow and succeed.
              Manny's years of experience and success have made him a
              knowledgeable leader in the digital marketing space.
            </p>

            <p>
              Carmen Lopez is an entrepreneur and business leader with a passion
              for developing the local economy and helping her community
              succeed. She has been involved in the management of the Doral
              Chamber since its inception in 2008 and has served in various
              capacities such as Vice President and CFO. Her commitment to the
              organization and to the local community is unwavering. Carmen has
              an extensive background in business, having worked in various
              industries for over 20 years. She is a mentor, a leader, and a
              powerhouse in the business world. As a result, she has been
              recognized for her work in the community. Carmen and Manny’s
              Bizzll solution was created to provide the local business
              communities with a responsive, reliable and professional
              experience that levels the playing field to succeed in the digital
              marketplace.
            </p>

            <p>
              Bizzll contains a directory where consumers can find local
              businesses that can supply the products and services that they
              require; it is also a powerful marketing tool to promote
              businesses online using tools such as social media, digital
              networking and its own video capability to transmit customized
              business content, live or on demand with two- way communication to
              receive instant feedback. This is just the beginning, there are
              many more resources coming in the next few months to bring
              together communities and businesses to ensure success and
              prosperity.
            </p>

            <p>
              At Bizzll, we are committed to providing businesses and consumers
              with the best possible experience.
            </p>

            <p>
              Thank you for considering Bizzll for your business needs. We look
              forward to working with you and helping your business grow!
            </p>
          </div>
        </div>
      </div>
      <ChatBoxContainer chatboxes={chatbox} closeChatbox={closeChatbox} />
    </div>
  );
}
