//** Import Modules */
import { faBell, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, Popover } from "antd";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import UserAvatar from "../../common/UserAvatar";

//** Import Components */
import ChatBoxContainer from "../../components/chatbox/ChatBoxContainer";
import ChatItem from "./ChatItem";
import FollowerItem from "./FollowerItem";
import HeaderUserMenu from "./HeaderUserMenu";

//** Import Context */
import { useSelector } from "react-redux";

export default function HeaderUserBar(props) {
  const {
    businessList,
    followersList,
    setBusinessList,
    setGetMessageNotification,
    getMessageNotification,
    getPurchaseListNotification,
    setPurchaseListNotification,
    count,
  } = props;
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [chatbox, setChatbox] = useState();
  const [showPopover, setShowPopover] = useState(false);

  //* Bring the user general info
  const user = useSelector((state) => state.user.user);

  //** Open the messages box
  const openMessages = () => {};

  //** Open the notifications box
  const openNotifications = () => {};

  //* Close menu box
  const closeMenu = () => {
    setOpenUserMenu(false);

    document.querySelector("body").removeEventListener("click", closeMenu);
  };
  const getMessageCount = () => {
    return businessList.filter((elem) => {
      if (elem.latestMessage.is_business === 1) {
        return (
          elem.latestMessage.unread === 1 &&
          elem.businessInfo.owner_id !== user.id
        );
      } else {
        return (
          elem.latestMessage.unread === 1 &&
          elem.businessInfo.owner_id === user.id
        );
      }
    }).length;
  };

  const handleOpenChatbox = (data) => {
    setShowPopover(false);
    const isBusiness = data.isBusiness || false;
    const newChatbox = {
      sender: isBusiness ? data.businessInfo : data.senderInfo,
      receiver: isBusiness ? data.senderInfo : data.businessInfo,
      is_business: isBusiness,
    };
    setChatbox(newChatbox);

    const newBusinessList = businessList.map((elem) => {
      if (elem.id === data.id) {
        elem.latestMessage.unread = 0;
      }
      return elem;
    });
    setBusinessList(newBusinessList);
  };

  return (
    <div id="header-userbar">
      <Popover
        title="Dashboard Menu"
        trigger="click"
        content={<HeaderUserMenu user={user} />}
        placement="bottomRight"
      >
        <button className="user-avatar">
          <button className="user-avatar">
            <UserAvatar user={user} size={40} single={true} />
          </button>
        </button>
      </Popover>

      <Popover
        title="Your Messages"
        trigger="click"
        content={
          businessList && businessList.length ? (
            businessList.map((chat, index) => (
              <ChatItem
                data={chat}
                key={index}
                onClick={() => handleOpenChatbox(chat)}
              />
            ))
          ) : (
            <div className="no-messages">You have 0 Message</div>
          )
        }
        placement="bottomRight"
        onVisibleChange={(isOpen) => {
          if (isOpen) {
            setGetMessageNotification(!getMessageNotification);
            setPurchaseListNotification(getPurchaseListNotification);
          }
        }}
      >
        <button onClick={openMessages} className="userbar-btns messages-btn">
          <Badge count={getMessageCount()} overflowCount={10} size="small">
            <FontAwesomeIcon
              icon={faEnvelope}
              onClick={() => setShowPopover(!showPopover)}
            />
          </Badge>
        </button>
      </Popover>
      <Popover
        title="Your Notifications"
        trigger="click"
        content={
          followersList.length ? (
            followersList.map((follower, index) => {
              return (
                <Link
                  params={{ testvalue: "hello" }}
                  to={{
                    pathname:
                      follower.component === "follow"
                        ? `https://dashboard.bizzll.com/business/followers/?businessID=${follower.business_id}`
                        : follower.component === "user_appointment"
                        ? `/member-dashboard/my-appointments`
                        : follower.component === "admin_appointment"
                        ? `/member-dashboard/virtual-office/${follower.item_referenced}`
                        : `/business/${follower?.businessInfo?.handler?.meta_value}`,
                    state: {
                      advertisementId:
                        follower.component !== "follow" &&
                        follower.advertisement
                          ? follower.advertisement.activity_wall_id
                          : "",
                    },
                  }}
                >
                  <FollowerItem
                    data={follower}
                    key={index}
                    // onClick={() => handleOpenChatbox(chat)}
                  />
                </Link>
              );
            })
          ) : (
            <div className="no-notifications">You have 0 Notification</div>
          )
        }
        placement="bottomRight"
        onVisibleChange={props.destroyNotifications}
      >
        <button
          onClick={openNotifications}
          className="userbar-btns notifications-btn"
        >
          <Badge
            count={followersList.filter((follower) => follower.is_new).length}
            overflowCount={10}
            size="small"
          >
            <FontAwesomeIcon icon={faBell} />
          </Badge>
        </button>
      </Popover>

      <ChatBoxContainer
        chatboxes={chatbox}
        closeChatbox={() => setChatbox(null)}
      />

      {openUserMenu && <HeaderUserMenu closeMenu={closeMenu} user={user} />}
    </div>
  );
}
