import React, { useState, useEffect } from "react";
import { Button, Menu, Dropdown, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosWithAuth from "../../utils/axiosWithAuth";
import "../../../src/assets/css/floatingButton.css";
import { DASHBOARD_URL, FE_URL } from "../../common/constants";

export default function FloatingButton() {
  const [visible, setVisible] = useState(true);

  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    getAllBusiness();
  }, []);

  const getAllBusiness = async () => {
    try {
      const response = await axiosWithAuth().get(`/api/business/owner`);
      setBusinesses(response.data);
    } catch (error) {
      console.error("Error getting businesses", error);
    }
  };

  // Generate localstorage to know which business you are managing
  const manageBusiness = (id) => {
    localStorage.setItem("editBusiness", id);

    window.location.replace(`${DASHBOARD_URL}/business`);
  };

  const menu = (
    <Menu>
      {businesses &&
        businesses.map((business) => (
          <Menu.Item
            key={business.url}
            onClick={() => manageBusiness(`${business.id}`)}
          >
            {business.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <>
      {businesses && businesses.length > 0 && (
        <div className={`floating-button ${visible ? "" : "hidden"}`}>
          <Tooltip title="Businesses" placement="left">
            <Dropdown overlay={menu} trigger={["click"]} placement="topLeft">
              <Button type="primary" size="large">
                <FontAwesomeIcon icon="fa-solid fa-briefcase" />
              </Button>
            </Dropdown>
          </Tooltip>

          {/* <Button
        className="toggle-button"
        shape="circle"
        onClick={() => setVisible(!visible)}
      >
        {visible ? "-" : "+"}
      </Button> */}
        </div>
      )}
    </>
  );
}
