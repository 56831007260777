/* eslint-disable react-hooks/exhaustive-deps */
//** Import Modules */
import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import axiosGeneral from "../../utils/axiosGeneral";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//** Import Assets */
import "../../assets/css/Profile.css";

//** Import Components */
import ProfileEventsPage from "./ProfileEventsPage";
import ProfileServicesPage from "./ProfileServicesPage";
import ProfileRestaurantsPage from "./ProfileRestaurantsPage";
import ProfileHeader from "./ProfileHeader/ProfileHeader";
import ProfileMainPage from "./ProfileMainPage";
import ProfileReviewsPage from "./ProfileReviewsPage";
import ProfileSubNav from "./ProfileSubNav";
import ProfileWidgets from "./ProfileWidgets";

//** Import Context */

import ChatBoxContainer from "../../components/chatbox/ChatBoxContainer";
import { useOpenChatbox } from "../../hooks/useOpenChatbox";
import ProfileAboutPage from "./ProfileAboutPage";

export default function Profile(props) {
  const { location } = props;

  //* Get the params from the route
  const routeParams = useParams();

  //* Bring the user info
  const user = useSelector((state) => state.user.user);

  //* Get the base URL for the profile pages
  const baseURL = "/business";

  //* Get The Business Data
  const [business, setBusiness] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [isVirtualConsultation, setIsVirtualConsultation] = useState("default");
  let navigate = useNavigate();
  const [chatbox, setChatbox] = useState();
  const [cartData, setCartData] = useState([]);

  //* Get the total number of followers
  const [followerCount, setFollowerCount] = useState(0);

  //* Get the chatbox hook
  const [chatboxes, openChatbox, closeChatbox] = useOpenChatbox(
    user,
    business,
    false
  );

  useEffect(() => {
    const APIURL = `/api/business/username/${routeParams.username}`;

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        console.log(res.data);
        setBusiness(res.data);
        setIsLoading(false);
        if (
          res.data.meta_data &&
          res.data.meta_data.business_package_type === "free"
        ) {
          navigate("/freeUser");
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [routeParams.username]);

  useEffect(() => {
    if (business && business.id && !loading) {
      visitorOfBusiness();
      getCount();
    }
  }, [/*business, loading*/ user]);

  const getCount = () => {
    const APIURL = `/api/business/follower/count/${business.id}`;

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        setFollowerCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const visitorOfBusiness = async () => {
    try {
      const response = await axiosGeneral().post(
        `${process.env.REACT_APP_BE_API_URL}/api/business/visitor`,
        {
          typeId: business.id,
          component: "profile_visit",
          userId: user?.id,
          type: "business",
        }
      );

      return response;
    } catch (err) {
      console.log(err.response);
    }
  };

  const updateFollowerCount = (operation) => {
    if (operation === "add") {
      setFollowerCount(followerCount + 1);
    } else {
      setFollowerCount(followerCount - 1);
    }
  };

  return (
    <div id="bizzll-business-profile">
      <ProfileHeader
        user={user}
        business={business}
        loading={loading}
        baseURL={baseURL}
        updateFollowerCount={updateFollowerCount}
        openChatbox={openChatbox}
        chatboxes={chatboxes}
        setChatbox={setChatbox}
        cartData={cartData}
        setCartData={setCartData}
        hasBusinesses={location && location.query ? location.query : ""}
      />

      <div className="profile-inner">
        {!loading ? (
          <>
            <div className="inner-container">
              <ProfileSubNav
                baseURL={baseURL}
                business={business}
                followerCount={followerCount}
                hasBusinesses={location && location.query ? location.query : ""}
              />

              <div id="profile-page-content">
                <div className="bizzll-profile-page-inner">
                  <Routes>
                    {/* Profile Homepage */}
                    <Route
                      element={
                        <ProfileMainPage
                          business={business}
                          user={user}
                          propsData={props}
                          hasBusinesses={
                            location && location.query ? location.query : ""
                          }
                        />
                      }
                      path="/"
                    />

                    {/* Events List Page */}
                    <Route
                      element={
                        <ProfileEventsPage
                          business={business}
                          user={user}
                          propsData={props}
                          hasBusinesses={
                            location && location.query ? location.query : ""
                          }
                        />
                      }
                      path={`/events`}
                    />

                    {/* Business About Page */}
                    <Route
                      element={
                        <ProfileAboutPage
                          business={business}
                          hasBusinesses={
                            location && location.query ? location.query : ""
                          }
                        />
                      }
                      path={`/about`}
                    />

                    {/* Book An Appointment Page */}
                    <Route
                      element={
                        <ProfileServicesPage
                          business={business}
                          user={user}
                          propsData={props}
                          hasBusinesses={
                            location && location.query ? location.query : ""
                          }
                        />
                      }
                      path="/services"
                      exact
                    />

                    {/* Restaurant Page */}
                    <Route
                      element={
                        <ProfileRestaurantsPage
                          business={business}
                          user={user}
                          propsData={props}
                          hasBusinesses={
                            location && location.query ? location.query : ""
                          }
                        />
                      }
                      path="/menu"
                      exact
                    />

                    {/* Reviews Page */}
                    <Route
                      element={
                        <ProfileReviewsPage
                          business={business}
                          user={user}
                          propsData={props}
                          hasBusinesses={
                            location && location.query ? location.query : ""
                          }
                        />
                      }
                      path={`/reviews`}
                    />
                  </Routes>
                </div>

                <div className="profile-page-widgets">
                  {!loading && (
                    <ProfileWidgets
                      business={business}
                      isVirtualConsultation={isVirtualConsultation}
                      setIsVirtualConsultation={setIsVirtualConsultation}
                      userId={user?.id}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Skeleton active />
        )}
      </div>

      <ChatBoxContainer
        chatboxes={chatbox || chatboxes}
        closeChatbox={closeChatbox}
      />
    </div>
  );
}
