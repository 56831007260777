//** Temp Assets */
import { Button, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Advertisements from "../../components/ads/Advertisements";
import axiosGeneral from "../../utils/axiosGeneral";
const { Option } = Select;

export default function EventSidebar(props) {
  const search = useLocation().search;
  const [city, setCity] = useState(new URLSearchParams(search).get("location"));
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(
    new URLSearchParams(search).get("category")
  );
  const [eventName, setEventName] = useState(
    new URLSearchParams(search).get("keyword")
  );
  let navigate = useNavigate();
  let categoryQuery = "";
  let locationQuery = "";
  let eventNameQuery = "";

  if (city) {
    locationQuery = `&location=${city || ""}`;
  }
  if (category) {
    categoryQuery = `&category=${category || ""}`;
  }
  if (eventName) {
    eventNameQuery = `&keyword=${eventName || ""}`;
  }

  useEffect(() => {
    if (search) {
      setCategory(new URLSearchParams(search).get("category"));
      setEventName(new URLSearchParams(search).get("keyword"));
      setCity(new URLSearchParams(search).get("location"));
    } else {
      setCategory(null);
      setCity(null);
      setEventName(null);
    }
  }, [search]);

  //* Get Categories
  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = () => {
    const APIURL = `/api/business/categories/list`;

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        const categoriesList = res.data.map((category) => {
          console.log("category", category);

          const lowercaseFirstLetterName =
            category.name.charAt(0).toLowerCase() + category.name.slice(1);

          return {
            value: category.name,
            key: lowercaseFirstLetterName,
          };
        });

        setCategories(categoriesList);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleFilterSubmit = () => {
    if (category || city || eventName) {
      navigate(
        `/events?searchDataPage=${0}${locationQuery}${categoryQuery}${eventNameQuery}`
      );
    } else {
      navigate(`/events`);
    }
  };

  const handleKeydown = (event) => {
    if (event.key === "Enter") {
      handleFilterSubmit();
    }
  };

  return (
    <>
      <div className="ad-space">
        <Advertisements addLocation="eventsSidebar" type="square" />
      </div>

      <div className="widget-box filter">
        <div className="event-widget-box event-search-form">
          <h3>Search by Name</h3>
          <input
            onChange={(e) => setEventName(e.target.value)}
            type="text"
            placeholder="Event Name"
            value={eventName ? eventName : ""}
            onKeyPress={handleKeydown}
          />

          <Button type="primary" onClick={handleFilterSubmit}>
            Search
          </Button>
        </div>
      </div>

      <div className="widget-box filter">
        <h3>Filter</h3>

        {/* <Select
          style={{
            width: "100%",
          }}
          onChange={(value) => setCategory(value)}
          size="large"
          allowClear={true}
          value={category}
          placeholder="Select Category"
        >
          <Option value="Accounting">Accounting</Option>
          <Option value="Design">Design</Option>
          <Option value="Development">Development</Option>
        </Select> */}

        <Select
          style={{
            width: "100%",
          }}
          onChange={(value) => {
            setCategory(value);
          }}
          size="large"
          allowClear={true}
          placeholder="Select Category"
          value={
            categories.map((category) => category.value).includes(category)
              ? category
              : null
          }
        >
          {categories.map((category) => {
            return (
              <Option
                // selected={headerSearchValue.category === category.value}
                value={category.value}
              >
                {category.value}
              </Option>
            );
          })}
        </Select>

        <Select
          style={{
            width: "100%",
            marginTop: 20,
            marginBottom: 15,
          }}
          onChange={(value) => setCity(value)}
          size="large"
          value={city}
          allowClear={true}
          placeholder="Select City"
        >
          <Option value="allCities">All Cities</Option>
          {props.cities
            ? props.cities.map((data) => {
                return <Option value={data.name}>{data.name}</Option>;
              })
            : null}
        </Select>

        <Button type="primary" onClick={handleFilterSubmit}>
          Filter
        </Button>
      </div>
    </>
  );
}
