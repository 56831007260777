//** Import Modules */
import { Drawer, Popover } from "antd";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useContext, useState } from "react";
import {
  faBars,
  faMagnifyingGlass,
  faUser,
  faUserPlus,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axiosWithAuth from "../../utils/axiosWithAuth";

//** Import Assets */
import "../../assets/css/Header.css";
import logo from "../../assets/images/bizzll-logo.png";

//** Import Components */
import { useEffect } from "react";
import logoBeta from "../../assets/images/bizzll-logo-beta-red.png";
import HeaderUserBar from "./HeaderUserBar";
import { SocketContext } from "../../contexts/SocketIO";
import { useSelector } from "react-redux";
import { DASHBOARD_URL, FE_URL } from "../../common/constants";

export default function Header(props) {
  // Get the props
  const user = useSelector((state) => state.user.user);
  const socket = useContext(SocketContext);

  const [businessList, setBusinessList] = useState([]);
  const [followersList, setFollowersList] = useState([]);

  const [getPurchaseListNotification, setPurchaseListNotification] = useState(
    []
  );

  const [getMessageNotification, setGetMessageNotification] = useState(false);
  const [getFollowerNotification, setGetFollowerNotification] = useState(false);
  const [count, setCount] = useState();
  const [visible, setVisible] = useState(false);
  const [width, setInnerWidth] = useState(window.innerWidth);

  const orderedBusinessList = () =>
    businessList.sort(function (a, b) {
      return (
        new Date(b.latestMessage && b.latestMessage.date_sent) -
        new Date(a.latestMessage && a.latestMessage.date_sent)
      );
    });

  const orderedFollowerssList = () =>
    followersList.sort(function (a, b) {
      return new Date(b.date_notified) - new Date(a.date_notified);
    });

  const destroyNotifications = (open) => {
    if (!open) {
      const filteredList = followersList.filter(
        (follower) => follower.is_new === 1
      );
      if (filteredList.length) {
        filteredList.map((list) => {
          axiosWithAuth()
            .post(`/api/business/destroyNotification/${list.id}`)
            .then(() => {})
            .catch((error) => console.log(error));
        });
        const newFollowerList = followersList.map((follower) => {
          follower.is_new = 0;
          return follower;
        });
        setFollowersList(newFollowerList);
      }
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      start: "top -20",
      end: 99999,
      toggleClass: { className: "header--scrolled", targets: "#site-header" },
    });
  }, []);

  useEffect(() => {
    const getToken = JSON.parse(localStorage.getItem("token"));
    if (getToken && user?.id) {
      const APIURL = `/api/business/owner`;
      axiosWithAuth()
        .get(APIURL)
        .then(async (res) => {
          let messages = [];
          let followersList = [];
          if (res.data.length) {
            for (const business of res.data) {
              const messagesCount = await axiosWithAuth().get(
                `/api/messages/threadlist/${business.id}/business`
              );

              const followers = await axiosWithAuth().get(
                `/api/business/getBusinessFollowers/${business.id}`
              );

              messagesCount.data.messageList.map(
                (list) => (list.isBusiness = true)
              );
              messages = [...messages, ...messagesCount.data.messageList];
              followersList = [...followersList, ...followers.data];
              // setBusinessList(messages);
            }
          }

          const privatemessage = await axiosWithAuth().get(
            `/api/messages/threadlist/${user.id}/user`
          );
          messages = [...messages, ...privatemessage.data.messageList];
          setBusinessList(messages);

          socket.on("delete-receiver-message", function (messageData) {
            if (
              messages.find((elem) => elem.latestMessage.id === messageData.id)
            ) {
              messages = messages.map((elem) => {
                if (elem.latestMessage.id === messageData.id) {
                  elem.latestMessage.is_deleted = 1;
                }
                return elem;
              });
              setBusinessList(messages);
            }
          });

          const appointments = await axiosWithAuth().get(
            `/api/business/getBusinessAppointments/${user.id}`
          );

          followersList = [...followersList, ...appointments.data];

          const filteredFollowerList = followersList.filter((item) => {
            if (
              item.component === "user_appointment" &&
              item.user_id === user.id
            ) {
              return item;
            } else if (
              item.user_id !== user.id &&
              item.component === "user_appointment"
            ) {
              // return item;
            } else if (item.user_id !== user.id) {
              return item;
            } else if (
              item.component === "admin_appointment" &&
              user.id === item.user_id
            ) {
              return item;
            }
          });

          setFollowersList(filteredFollowerList);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user, getMessageNotification, getFollowerNotification, socket]);

  useEffect(() => {
    socket.on("receive-notification", (userID, data, icon) => {
      if (user && user.id === userID) {
        if (data.className == "message") {
          setGetMessageNotification(() => !getMessageNotification);
        } else {
          setGetFollowerNotification(() => !getFollowerNotification);
        }
      } else if (user && user.id && data.is_business) {
        if (data.className == "message") {
          setGetMessageNotification(() => !getMessageNotification);
        }
      }
    });
  }, [user.id, socket]);

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <header id="site-header">
      <div className="inner-container">
        <div className="header-inner">
          <div className="mobile-toggle">
            <FontAwesomeIcon icon={faBars} onClick={showDrawer} />
          </div>

          <div className="mobile-search">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>

          <div id="logo">
            <a href="/">
              <img src={logoBeta} alt="Bizzll" />
            </a>
          </div>

          <div className="desktop-menu">
            <nav id="header-menu">
              <ul>
                <li>
                  <a href="/directory">Directory</a>
                </li>

                <li>
                  <a href="/bizzll-city">Cities</a>
                </li>
                <li>
                  <a href="/events">Events</a>
                </li>
                <li>
                  <a
                    href="https://video.bizzll.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Videos
                  </a>
                </li>
                <li>
                  <Popover
                    content={
                      <ul className="header-sub-menu">
                        <li>
                          <a href="/about">About</a>
                        </li>
                        {/* <li>
                          <a href='/groups'>Groups</a>
                        </li> */}
                        <li>
                          <a
                            href="https://support.bizzll.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Help
                          </a>
                        </li>
                      </ul>
                    }
                    placement="bottomRight"
                    trigger="click"
                  >
                    <label style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon icon={faBars} />
                    </label>
                  </Popover>
                </li>
              </ul>
            </nav>
          </div>

          <Drawer
            title="Menu"
            placement="left"
            onClose={closeDrawer}
            visible={visible}
            width="70%"
            className="mobile-menu-drawer"
          >
            <>
              <ul className="main-mobile-menu">
                <li>
                  <a href="/" onClick={closeDrawer}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="/directory" onClick={closeDrawer}>
                    Directory
                  </a>
                </li>
                <li>
                  <a href="/about" onClick={closeDrawer}>
                    About
                  </a>
                </li>
                <li>
                  <a href="/bizzll-city" onClick={closeDrawer}>
                    Cities
                  </a>
                </li>
                <li>
                  <a href="/events" onClick={closeDrawer}>
                    Events
                  </a>
                </li>
                <li>
                  <a
                    href="https://video.bizzll.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Videos
                  </a>
                </li>
                <li>
                  <a href="/groups" onClick={closeDrawer}>
                    Groups
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.bizzll.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Help
                  </a>
                </li>
              </ul>

              <ul className="secondary-mobile-menu">
                <li>
                  <a href={`${DASHBOARD_URL}/?redirect=${FE_URL}`}>
                    <FontAwesomeIcon icon={faUser} /> Login
                  </a>
                </li>
                <li>
                  <a href={`${DASHBOARD_URL}/?action=register`}>
                    <FontAwesomeIcon icon={faRightToBracket} /> Register
                  </a>
                </li>
              </ul>
            </>
          </Drawer>

          <div className="header-auth">
            {user.id ? (
              <HeaderUserBar
                businessList={orderedBusinessList()}
                followersList={orderedFollowerssList()}
                destroyNotifications={destroyNotifications}
                setBusinessList={setBusinessList}
                setGetMessageNotification={setGetMessageNotification}
                getMessageNotification={getMessageNotification}
                getPurchaseListNotification={getPurchaseListNotification}
                setPurchaseListNotification={setPurchaseListNotification}
                count={count}
                user={user}
              />
            ) : (
              <div id="header-buttons">
                <div className="desktop-buttons">
                  <button
                    onClick={() => {
                      window.location.href = `${DASHBOARD_URL}/?redirect=${FE_URL}`;
                    }}
                  >
                    Login
                  </button>
                  <button
                    onClick={() => {
                      window.location.href = `${DASHBOARD_URL}/?action=register`;
                    }}
                  >
                    Join <img src={logo} alt="" style={{ width: "50px" }} />
                  </button>
                </div>

                <div className="mobile-buttons">
                  <Popover
                    title="User Account"
                    trigger="click"
                    content={
                      <>
                        <ul>
                          <li>
                            <a href={`${DASHBOARD_URL}/?redirect=${FE_URL}`}>
                              Login
                            </a>
                          </li>
                          <li>
                            <a href={`${DASHBOARD_URL}/?action=register`}>
                              Join Bizzll
                            </a>
                          </li>
                        </ul>
                      </>
                    }
                    placement="bottomRight"
                  >
                    <button className="user-avatar">
                      <FontAwesomeIcon icon={faUser} />
                    </button>
                  </Popover>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
